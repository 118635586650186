<template>
    <div>

        <!--Edit Organization Modal-->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable title="Edit Organization" v-model="edit.modal">
            <b-row>
                <!--Organization Name-->
                <b-col sm="7">
                    <b-form-group>
                        <label>Organization Name:</label>
                        <b-form-input v-model="edit.data.orgName" v-uppercase/>
                    </b-form-group>
                </b-col>
                <!--Status-->
                <b-col sm="5">
                    <b-form-group>
                        <label>Status:</label>
                        <b-form-select :options="['NO STATUS','ACTIVE', 'INACTIVE']" v-model="edit.data.status"/>
                    </b-form-group>
                </b-col>
                <!--Description-->
                <b-col sm="12">
                    <b-form-group>
                        <label>Description:</label>
                        <textarea maxlength="2000" class="form-control rounded-0" rows="7"
                                  v-model="edit.data.description" type="text"></textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="edit.modal=false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="putOrganization()" size="sm" variant="primary">Submit</b-button>
            </template>
        </b-modal>

        <!--Update Profiles-->
        <b-modal class="modal-dark" no-close-on-backdrop scrollable size="lg"
                 title="Add Profiles To Organization"
                 v-model="profiles.modal">
            <v-client-table :columns="profiles.columns" :data="profiles.all" :options="profiles.tOptions"
                            :theme="profiles.theme"
                            class="dataTable" ref="dTable" style="width: 100%">
                <b-form-checkbox :value="props.row.profileId.toString()" slot="check" slot-scope="props"
                                 v-model="profiles.selected"></b-form-checkbox>
                <div slot="h__check">
                    <b-form-checkbox
                            @change="profiles.selected = unmarkAndMarkAll(profiles.selected, profiles.allMarked)"
                            class="check-all" style="padding-left: 24px"
                            type="checkbox"
                            v-model='profiles.allMarked'></b-form-checkbox>
                </div>
            </v-client-table>
            <template slot="modal-footer">
                <b-button @click="profiles.modal = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="updateProfiles(edit.data)" size="sm" variant="primary">Submit</b-button>
            </template>
        </b-modal>

        <!-- Are You Sure Delete -->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Delete Organization" class="modal-dark"
                 id="deleteOrg">
            <p>If you would like to permanently remove this organization please type the name Below.</p>
            <br>
            <p>Enter "{{organization.orgName}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input v-model="organizationNameVerification" id="basicName3" type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="$bvModal.hide('deleteOrg')">Cancel</b-button>
                <b-button size="sm" :disabled="organizationNameVerification !== organization.orgName"
                          variant="danger" @click="deleteOrganization">
                    <fa-icon :icon="['fas', 'trash-can']"/>Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Page-->
        <b-card footer-tag="footer" header="Card outline primary" header-tag="header">
            <!--Header-->
            <div slot="header">
                <span class="d-flex w-100 justify-content-between">
                    <!--<h5 style="margin-bottom: 0"><b>{{createTemplate.orgName}}</b></h5>-->
                    <h5 style="margin-bottom: 0">
                        <b>{{organization.orgName}}</b>&nbsp;
                        <span style="transform: translateY(-2px)"
                              :class="'badge badge-' + (organization.status === 'ACTIVE' ? 'success' : (organization.status === 'INACTIVE' ? 'danger': (organization.status === 'NO STATUS' ? 'secondary':'dark')))">
                            {{organization.status}}
                        </span>
                    </h5>
                    <span>
                        <fa-icon v-if="profiles.linked.length > 0" :icon="['fas', 'print']" @click="setUpPrintModal"
                                 size="lg" style="padding-right: 10px; cursor: pointer;"/>
                        <fa-icon :icon="['fas', 'trash-can']" @click="$bvModal.show('deleteOrg')"
                                 size="lg" style="padding-right: 10px; cursor: pointer;"/>
                        <fa-icon :icon="['fas', 'pen-to-square']" @click="openEdit" size="lg" style="cursor: pointer;"/>
                    </span>
                </span>
            </div>
            <!--Body Cards-->
            <b-row>
                <!--Profiles/Tabs/Map-->
                <b-col sm="9" class="d-flex flex-column" style="padding-right: 10px; padding-left: 0; flex-wrap: wrap">
                    <!--Tabs/Map-->
                    <span class="container-fluid" style="padding: 0">
                        <b-row style="width: 100%; margin: 0;">
                            <!--Tabs-->
                            <b-col xl="8" style="padding-right: 5px; padding-left: 0;">
                                <b-card bg-variant="dark" no-body style="border-color: #000001; background-color: #0e0e0e !important; margin-bottom: 10px; height: 530px" fluid>
                                    <b-tabs lazy fluid variant="dark" class="d-flex flex-column" style="height: 530px; overflow: auto;">
                                        <!--Address Tab-->
                                        <b-tab style="background-color: #2f353a; margin: 0; min-height: 489px"
                                               title="Addresses" class="active tab-scroll">
                                            <AddressTabComponent :addresses="organization.addresses"
                                                                 :title="organization.orgName"
                                                                 :editable="true"
                                                                 :scope="orgId"
                                                                 :linkId="$route.params.id.toString()"
                                                                 :linkType="'Org'"
                                                                 :color="'blue'"
                                                                 @deleteAddress="deleteAddress"/>
                                        </b-tab>
                                        <!--Documents/Media Tab-->
                                        <b-tab style="background-color: #2f353a; margin: 0; min-height: 489px" title="Documents/Media" class="tab-scroll">
                                            <DocumentTabComponent :editable="true"
                                                                  :documents="organization.documents"
                                                                  :editMethod="changeDocumentDesc"
                                                                  :deleteMethod="deleteEmbeddedDoc"
                                                                  :submitUploadMethod="addOrgDocument"/>
                                        </b-tab>
                                        <!--LE-Reports Tab-->
                                        <b-tab style="margin: 0; min-height: 489px" title="LE-Reports">
                                            <LeReportTabComponent :scope="orgId"
                                                                  :editable="true"
                                                                  :leReportsObject="organization.leReports"
                                                                  :linkId="$route.params.id.toString()"
                                                                  :linkType="'Org'"
                                                                  @deleteLeReport="deleteLeReport"/>
                                        </b-tab>
                                        <!--Profile Addresses-->
                                        <b-tab style="background-color: #2f353a; margin: 0; min-height: 489px"
                                               title="Profile Addresses" class="tab-scroll">
                                            <AddressTabComponent :addresses="profileAddresses"
                                                                 :editable="false"
                                                                 :scope="orgId"
                                                                 :linkId="$route.params.id.toString()"
                                                                 :linkType="'Org'"
                                                                 :color="'red'"/>
                                        </b-tab>
                                        <!--Profile Vehicles-->
                                        <b-tab style="background-color: #2f353a; margin: 0; min-height: 489px"
                                               title="Profile Vehicles" class="tab-scroll">
                                                    <VehicleTabComponent :vehicleArray="profileVehicles"
                                                                         :scope="orgId"
                                                                         :editable="false"
                                                                         :linkId="$route.params.id.toString()"
                                                                         :linkType="'Org'"/>
                                        </b-tab>
                                        <!--Profile Social Media-->
                                        <b-tab style="background-color: #2f353a; margin: 0; min-height: 489px"
                                               title="Profile Social Media" class="tab-scroll p-0">
                                            <b-row>
                                                <b-col lg="12" v-for="(site, index) in socialMediaSites" :key="index"
                                                       v-if="site.profiles.length > 0">
                                                    <b-card no-body
                                                            :style=" 'margin: 5px; background: ' + socialMediaTheme(site.Name, false)">
                                                        <span :style="'border-top-right-radius: 3px; border-top-left-radius: 3px; border: 0 solid #2b2b2b;'">
                                                            <h5 style="margin: 5px; padding: 0;">
                                                                <fa-icon :icon="socialMediaTheme(site.Name, true)"/>
                                                                {{site.Name}}
                                                            </h5>
                                                        </span>
                                                        <b-card :key="sIndex" v-for="(profile, sIndex) in site.profiles"
                                                                no-body
                                                                style="margin: 5px; padding: 0 5px 0 5px; color: white">
                                                            <h5><strong>MEMBER: {{profile.name}}</strong></h5>
                                                            <b-card no-body
                                                                    style="padding: 5px; background-color: #282d32; margin: 5px">
                                                                <div class="d-flex w-100 justify-content-between">
                                                                    <span>
                                                                        <strong>Username:</strong>&nbsp;{{profile.socialMedia.userName}}
                                                                    </span>
                                                                    <a :href="profile.socialMedia.link"
                                                                       :title="profile.socialMedia.link"
                                                                       style="color: white"
                                                                       data-placement="top" data-toggle="tooltip"
                                                                       target="_blank">
                                                                        <fa-icon :icon="['fas', 'up-right-from-square']" style="cursor: pointer;"/>
                                                                    </a>
                                                                </div>
                                                                <div v-if="verifyThereAreNoNotes(profile.socialMedia.notes)"
                                                                     style="width: 100%; display: flex; align-items: center">
                                                                    <span style="text-align: center; width: 100%">no notes to display</span>
                                                                </div>
                                                                <div v-else>
                                                                    <div><strong>Notes: </strong></div>
                                                                    <div :key="i" v-for="(note, i) in profile.socialMedia.notes">
                                                                        <b-card no-body style="margin: 5px; padding-left: 5px;background-color: #1f1f1f;border-color: black">
                                                                            <span v-if="note.text !== ''">{{note.text}}</span>
                                                                            <div class="text-muted text-uppercase font-weight-bold font-xs"
                                                                                 style=" float: right; margin-right: 10px;">Created By: {{note.createdBy}}
                                                                            </div>
                                                                        </b-card>
                                                                    </div>
                                                                </div>
                                                            </b-card>
                                                        </b-card>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-tab>

                                        <!--Profile LE-Reports-->
                                        <b-tab title="Profile LE-Reports" class="tab-scroll">
                                            <LeReportTabComponent :scope="orgId"
                                                                  :editable="false"
                                                                  :leReports="profileLeReports"
                                                                  :linkId="$route.params.id.toString()"/>
                                        </b-tab>

                                        <!--Profile Markings-->
                                        <b-tab title="Profile Markings" class="tab-scroll">
                                            <b-col style="padding: 0" lg="12">
                                                <span :key="index + 't'" v-for="(profile, index) in organization.profiles"
                                                      v-if="profile.markings && profile.markings.length > 0">
                                                    <b-card style="border-color: #0e0e0e; background-color: #202020;" no-body>
                                                        <span class="d-flex w-100 justify-content-between" style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                                                            <b style="font-size: medium">{{ nameify(profile) }}</b>
                                                        </span>
                                                        <div style="margin: 20px;" v-for="(marking, mIndex) in profile.markings" :key="mIndex">
                                                            <MarkingComponent :marking="marking"
                                                                              :editable="false"
                                                                              :scope="orgId"/>
                                                        </div>
                                                    </b-card>
                                                </span>
                                            </b-col>
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                            </b-col>

                            <!--Map-->
                            <b-col xl="4" style="padding: 0">
                                <MapComponent :height="530"/>
                            </b-col>
                        </b-row>
                    </span>

                    <!--Members-->
                    <b-card bg-variant="dark" no-body
                            style="width: 100%; margin: 5px; height: 530px">

                        <!--Members Header-->
                        <div slot="header">
                            <div class="d-flex w-100 justify-content-between">
                                <p style="margin-bottom: 0"><b>Members</b></p>
                                <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" @click="openModalProfile()"/>
                            </div>
                        </div>

                        <!--Member Cards-->
                        <b-row style="margin: 0; height: 483px; overflow-y: auto" align-v="stretch">
                            <span v-if="profiles.linked && profiles.linked.length > 0"
                                  style="display: flex; flex-wrap: wrap">
                                <b-card v-for="(profile, index) in profiles.linked" :key="index + 'Id'" no-body
                                        style="width: 250px; height: 420px; margin: 10px; background-color: #0e0e0e; padding: 0">
                                    <span class="d-flex justify-content-between">
                                        <span/>
                                        <strong style="text-align: center;">{{nameify(profile)}}</strong>
                                        <router-link class="non-link"
                                                     :to="{ name: 'Profile Details', params: {id: profile.profileId}}"
                                                     target="_blank">
                                            <fa-icon :icon="['fas', 'up-right-from-square']" style="padding-right: 5px;"/>
                                        </router-link>
                                    </span>
                                    <div class="image-parent">
                                        <img v-auth-image="profilesImage(profile.images)" class="img-center" alt=""/>
                                    </div>
                                    <div v-if="profile.ribbons && Array.isArray(profile.ribbons) && profile.ribbons.length > 0"
                                          style="display: flex; align-items: center; height: 17px; width: 250px">
                                        <div v-for="(r, i) in profile.ribbons" :key="i + 'r1'"
                                              :class="'bg-' + ribbonVariant(r.ribbon)"
                                              style="flex: 1; height: 100%; display: flex; align-items: center">
                                            <p style="margin: 0; width: 100%; text-align: center; font-size: 12px">{{r.ribbon}}</p>
                                        </div>
                                    </div>
                                    <strong style="text-align: center;">
                                        <span v-if="profile.birthMonth">{{profile.birthMonth}}</span>
                                        <span v-else>XX</span>
                                        <span v-if="profile.birthDay">/{{profile.birthDay}}</span>
                                        <span v-else>/XX</span>
                                        <span v-if="profile.birthYear">/{{profile.birthYear}}</span>
                                        <span v-else>/XXXX</span>
                                    </strong>
                                </b-card>
                            </span>
                        </b-row>
                    </b-card>
                </b-col>

                <!--Description/Notes-->
                <b-col sm="3" class="d-flex flex-column" style="padding: 0">
                    <!--Description-->
                    <b-card bg-variant="dark" no-body
                            style="height: 354px; width: 100%; margin-bottom: 10px;">
                        <div slot="header">
                            <p style="margin-bottom: 0"><b>Description</b></p>
                        </div>
                        <span style="overflow-y: auto; padding: 10px; white-space: pre-line">{{organization.description}}</span>
                    </b-card>
                    <!--Notes-->
                    <NotesPageComponent :notes="organization.notes"
                                        :addMethod="addNote"
                                        :editMethod="editNote"
                                        :deleteMethod="deleteNote"
                                        :height="710"/>
                </b-col>
            </b-row>
        </b-card>

        <OrganizationPrintSetup @getPrintOptions="getPrintOptions" :open="openPrintSetup" :organization="organization" :orgName="orgName"
                                @closed="closePrintOptions()" @print="setUpPrint" />
        <OrganizationPrint :printOptions="printOptions" :open="openPrintSetup" :organization="organization" :orgName="orgName"
                                @closed="closePrintOptions()" @print="print"/>

    </div>
</template>

<script>
    import risc from '@/services/risc.js';
    import Vue from 'vue';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import {ClientTable} from 'vue-tables-2'
    import OrganizationPrintSetup from "@/views/expansion_modules/risc/organization_management/OrganizationPrintSetup";
    import OrganizationPrint from "@/views/expansion_modules/risc/organization_management/OrganizationPrint";
    import VueHtmlToPaper from 'vue-html-to-paper';
    import AddressTabComponent from "@/views/expansion_modules/risc/components/address_components/AddressTabComponent";
    import LeReportTabComponent from "@/views/expansion_modules/risc/components/lereport_components/LeReportTabComponent";
    import VehicleTabComponent from "@/views/expansion_modules/risc/components/vehicle_components/VehicleTabComponent";
    import DocumentTabComponent from "@/views/expansion_modules/risc/components/documents_components/DocumentTabComponent";
    import NotesPageComponent from "@/views/expansion_modules/risc/components/notes_components/NotesPageComponent";
    import MarkingComponent from "@/views/expansion_modules/risc/components/marking_components/MarkingComponent";
    import MapComponent from "@/views/expansion_modules/risc/components/MapComponent";

    const options = {
        name: '_blank',
        specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes',
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
    }

    Vue.use(VueHtmlToPaper, options);
    Vue.use(ClientTable)

    export default {
        name: "Detail",
        props: ["id"],
        components: {
            MarkingComponent,
            DocumentTabComponent,
            OrganizationPrintSetup,
            OrganizationPrint,
            datePicker,
            AddressTabComponent,
            VehicleTabComponent,
            LeReportTabComponent,
            NotesPageComponent,
            MapComponent
        },
        data() {
            return {
                orgId: "org" + this.$route.params.id,
                printOptions: {},
                openPrintSetup: false,
                sites: [
                    'FACEBOOK', 'YOUTUBE', 'WHATSAPP', 'MESSENGER', 'WECHAT', 'INSTAGRAM', 'TUMBLR', 'TIK TOK',
                    'TWITTER', 'REDDIT', 'LINKEDIN', 'SNAPCHAT', 'PINTEREST', 'LINE', 'TELEGRAM', 'GOOGLE+', 'MEETUP'
                ],
                profileLeReports: [],
                profileAddresses: [],
                profileVehicles: [],
                socialMediaSites: {
                    'FACEBOOK': {name: 'FACEBOOK', profiles: []},
                    'YOUTUBE': {name: 'YOUTUBE', profiles: []},
                    'WHATSAPP': {name: 'WHATSAPP', profiles: []},
                    'MESSENGER': {name: 'MESSENGER', profiles: []},
                    'WECHAT': {name: 'WECHAT', profiles: []},
                    'INSTAGRAM': {name: 'INSTAGRAM', profiles: []},
                    'TUMBLR': {name: 'TUMBLR', profiles: []},
                    'TIKTOK': {name: 'TIK TOK', profiles: []},
                    'TWITTER': {name: 'TWITTER', profiles: []},
                    'REDDIT': {name: 'REDDIT', profiles: []},
                    'LINKEDIN': {name: 'LINKEDIN', profiles: []},
                    'SNAPCHAT': {name: 'SNAPCHAT', profiles: []},
                    'PINTEREST': {name: 'PINTEREST', profiles: []},
                    'LINE': {name: 'LINE', profiles: []},
                    'TELEGRAM': {name: 'TELEGRAM', profiles: []},
                    'GOOGLE': {name: 'GOOGLE+', profiles: []},
                    'MEETUP': {name: 'MEETUP', profiles: []}
                },
                organizationNameVerification: '',
                organization: {
                    documents: [],
                    addresses: []
                },
                edit: {
                    modal: false,
                    data: {},
                },
                profiles: {
                    linked: [],
                    all: [],
                    selected: [],
                    allMarked: false,
                    modal: false,
                    theme: 'bootstrap4',
                    columns: ['check', 'tableName', 'tableDOB'],
                    tOptions: {
                        sortable: ['tableName', 'tableDOB'],
                        filterable: ['tableName', 'tableDOB'],
                        orderBy: {column: 'tableName', ascending: true},
                        headings: {tableName: 'Name', tableDOB: 'DOB',},
                        sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                        pagination: {chunk: 5, edge: false, nav: 'scroll'},
                        perPage: 25,
                        skin: 'table table-striped table-hover',
                    },
                },
                addressIdArray: [],
                orgName: ""
            }
        },

        async created() {
            await this.getOrganization();
        },

        methods: {
            addNote(note) {
                this.organization.notes.push(note)
                risc.putOrganizations(this.organization).then(() => {
                    this.getOrganization()
                });
            },
            addOrgDocument(description, fileId) {
                this.organization.documents.push({
                    description: description,
                    storageFileName: fileId,
                    arrayOrder: this.organization.documents.length
                });
                risc.putOrganizations(this.organization).then(() => {
                    this.getOrganization();
                });
            },
            changeDocumentDesc(doc) {
                this.organization.documents.forEach(document => {
                    if (document.documentId === doc.documentId) {
                        document.description = doc.description
                    }
                })
                risc.putOrganizations(this.organization).then(() => {
                    this.getOrganization();
                });
            },
            closePrintOptions() {
                this.openPrintSetup = false;
            },
            dateify(month, day, year) {
                let result = "";
                result += (!month ? 'XX' : (month < 10 ? "0" + month : month)) + '/';
                result += (!day ? 'XX' : (day < 10 ? "0" + day : day)) + '/';
                result += (!year ? 'XXXX' : year);
                return result;
            },
            deleteAddress(address) {
                this.organization.addresses = this.organization.addresses.filter(_ => _.addressId !== address.addressId);
            },
            deleteEmbeddedDoc(doc) {
                this.organization.documents = this.organization.documents.filter((document) => {
                    return document.documentId !== doc.documentId;
                });
                risc.putOrganizations(this.organization).then(() => {
                    this.getOrganization();
                });
            },
            deleteLeReport(leReport) {
                this.organization.leReports = this.organization.leReports.filter(_ => _.leReportId !== leReport.leReportId);
            },
            deleteNote(note) {
                this.organization.notes = this.organization.notes.filter(_ => _.noteId !== note.noteId);
                risc.putOrganizations(this.organization).then(() => {
                    this.getOrganization();
                });
            },
            deleteOrganization() {
                risc.deleteOrganizations(this.organization).then(() => {
                    window.location = '/#/risc/organizations'
                });
                this.$bvModal.hide("deleteOrg")
                this.deleteVerification = null;
            },
            editNote(note) {
                this.organization.notes.map(_ => {
                    if (_.noteId === note.noteId) {
                        _ = note;
                    }
                    return _;
                })
                risc.putOrganizations(this.organization).then(() => {
                    this.getOrganization()
                })
            },
            getFirstDocumentImage(docArray) {
                let stId = 0;
                docArray.forEach(doc => {
                    if (doc.arrayOrder === 0) {
                        stId = doc.storageFileName;
                    }
                })
                if (stId !== 0) {
                    return risc.getImage(stId);
                } else {
                    return risc.getImage(docArray[0].storageFileName);
                }
            },
            getImage(storageId) {
                if (storageId !== undefined) {
                    return risc.getImage(storageId);
                } else {
                    return ('/img/NoPictureOnFileGrey.png')
                }
            },
            async getOrganization() {
                this.profileVehicles = [];
                this.profileAddresses = [];
                this.profileLeReports = [];
                risc.getOneOrganization(this.id).then(response => {
                    this.organization = {};
                    this.organization = response.data;
                    this.orgName = this.organization.orgName
                    this.profiles.selected = [];
                    this.profileLeReports = [];
                    this.socialMediaSites = {}
                    this.sites.forEach(site => {
                        this.socialMediaSites[site] = {
                            Name: site,
                            profiles: [],
                        }
                    })
                    this.profiles.linked = response.data.profiles.sort((cur, oth) => {
                        let nCur = cur.lastName ? cur.lastName : "zzzzzz";
                        let nOth = oth.lastName ? oth.lastName : "zzzzzz";
                        return nCur.localeCompare(nOth) || (cur.firstName ? cur.firstName : "zzzzzz").localeCompare(oth.firstName ? oth.firstName : "zzzzzz")
                    });
                    response.data.profiles.forEach(profile => {
                        this.profiles.selected.push(profile.profileId);
                    });
                    this.makeProfilesDeep();
                })
            },
            getPrintOptions(options) {
                this.printOptions = Object.assign({}, options)
            },
            makeProfilesDeep() {
                // makes the profiles deep to reach the documents data
                let deepProfiles = new Array(this.profiles.selected.length).fill({})
                for (let i = 0; i < this.organization.profiles.length; i++) {
                    risc.getOneProfile(this.organization.profiles[i].profileId).then(response => {
                        this.organization.profiles[i] = response.data;
                        deepProfiles[i] = response.data
                        if (response.data.addresses && response.data.addresses.length > 0) {
                            //this.addAddressMarker(this.profileMarkers, response.data.addresses);
                            this.profileAddresses = this.profileAddresses.concat(response.data.addresses.map(_ => {
                                _.profileName = this.nameify(response.data);
                                return _;
                            }));
                        }
                        if (response.data.vehicles && response.data.vehicles.length > 0) {
                            this.profileVehicles = this.profileVehicles.concat(response.data.vehicles.map(_ => {
                                _.profileName = this.nameify(response.data);
                                return _;
                            }))
                        }
                        if (response.data.socialMedia && response.data.socialMedia.length > 0) {
                            let name = this.nameify(deepProfiles[i])
                            response.data.socialMedia.forEach(socialMedia => {
                                this.pushProfileSocialMedia(socialMedia, name)
                            })
                        }
                        if (response.data.leReports && response.data.leReports.length > 0) {
                            response.data.leReports.forEach(report => {
                                //this.addAddressMarker(this.leReportMarkers, response.data.addresses);
                                let deepLeReport = report;
                                const isUniqueReport = this.profileLeReports.every(function (leReport) {
                                    return leReport.leReportId !== deepLeReport.leReportId
                                })
                                if (isUniqueReport) {
                                    this.profileLeReports.push({...deepLeReport, profiles: [{
                                            name: this.nameify(deepProfiles[i]),
                                            profileId: deepProfiles[i].profileId
                                        }]});
                                } else {
                                    this.profileLeReports.forEach(leReport => {
                                        if (leReport.leReportId === report.leReportId) {
                                            leReport.profiles.push({
                                                name: this.nameify(deepProfiles[i]),
                                                profileId: deepProfiles[i].profileId
                                            })
                                        }
                                    })
                                }
                            })
                        }
                        this.$forceUpdate();
                    })
                }
            },
            nameify(profile) {
                return (
                    (profile.lastName ? profile.lastName : 'UNKNOWN') + ', '
                    + (profile.firstName ? profile.firstName : 'UNKNOWN') + ' '
                    + (profile.middleName === null  ? '' : profile.middleName.slice(0, 1))
                )
            },
            openEdit() {
                this.edit.modal = true;
                this.edit.data = JSON.parse(JSON.stringify(this.organization));
            },
            openModalProfile() {
                risc.getProfiles().then(response => {
                    this.profiles.all = response.data.map(profile => {
                        return({
                            ...JSON.parse(JSON.stringify(profile)),
                            tableDOB: this.dateify(profile.birthMonth, profile.birthDay, profile.birthYear),
                            tableName: this.nameify(profile)
                        })
                    })
                    this.profiles.modal = true
                })
            },
            print() {
                //the time-out on this function could potentially
                // leave a problem if they try to print a page with a ton a people as
                //this may not give enough time to get all the images
                setTimeout(() => {
                    this.$htmlToPaper('printMe');
                }, 1500);
            },
            profilesImage(images) {
                if (images && images.length >= 1) {
                    return (this.getFirstDocumentImage(images));
                } else {
                    return ('/img/NoPictureOnFileGrey.png');
                }
            },
            pushProfileSocialMedia(socialMedia, name) {
                this.socialMediaSites[socialMedia.site].profiles.push({
                    name: name,
                    socialMedia: socialMedia
                });
            },
            putOrganization() {
                //this.edit.data.profiles = JSON.parse(JSON.stringify(this.profiles.select.selected))
                risc.putOrganizations(this.edit.data).then(() => {
                    this.edit.data = {};
                    this.getOrganization();
                    this.edit.modal = false;

                });
            },
            reverseNotesArray() {
                this.organization.notes = this.organization.notes.reverse()
            },
            ribbonVariant(ribbon) {
                switch (ribbon) {
                    case 'IN CUSTODY':
                        return 'success'
                    case 'PAROLE/PROBATION':
                        return 'warning text-dark'
                    case 'WANTED':
                        return 'danger'
                    case 'OUT OF TOWN':
                        return 'warning text-dark'
                    case 'DECEASED':
                        return 'dark';
                    default:
                        return 'primary';
                }
            },
            setUpPrint(newOrgName) {
                //the time-out on this function could potentially
                // leave a problem if they try to print a page with a ton a people as
                //this may not give enough time to get all the images
                this.orgName = newOrgName;
                setTimeout(() => {
                    this.$htmlToPaper('printMe');
                }, 1500);
            },
            setUpPrintModal() {
                this.openPrintSetup = true;
                this.printOptions = {};
                this.orgName = this.organization.orgName;
            },
            socialMediaTheme(str, forIcon){
                /*sites: [*/
                /*    'FACEBOOK', 'YOUTUBE', 'WHATSAPP', 'MESSENGER', 'WECHAT', 'INSTAGRAM', 'TUMBLR', 'TIK TOK',*/
                /*    'TWITTER', 'REDDIT', 'LINKEDIN', 'SNAPCHAT', 'PINTEREST', 'LINE', 'TELEGRAM', 'GOOGLE+', 'MEETUP'*/
                /*],*/
                switch (str) {
                    case 'FACEBOOK':
                        return forIcon ? ['fab', 'facebook-f'] : '#4267B2; color: #FFFFFF';
                    case 'YOUTUBE' :
                        return forIcon ? ['fab', 'youtube'] : '#FF0000; color: #FFFFFF';
                    case 'WHATSAPP' :
                        return forIcon ? ['fab', 'whatsapp'] : '#25D366; color: #FFFFFF';
                    case 'MESSENGER' :
                        return forIcon ? ['fab', 'facebook-messenger'] : '#4267B2; color: #FFFFFF';
                    case 'WECHAT' :
                        return forIcon ? ['fab', 'weixin'] : '#5CC928; color: #FFFFFF';
                    case 'INSTAGRAM' :
                        return forIcon ? ['fab', 'instagram'] : 'linear-gradient(204deg, rgba(64,93,230,1) 0%,' +
                            ' rgba(88,81,219,1) 12%,' +
                            ' rgba(131,58,180,1) 25%,' +
                            ' rgba(193,53,132,1) 35%,' +
                            ' rgba(225,48,108,1) 49%,' +
                            ' rgba(253,29,29,1) 60%,' +
                            ' rgba(247,119,55,1) 72%,' +
                            ' rgba(252,175,69,1) 84%,' +
                            ' rgba(255,220,128,1) 94%); color: #FFFFFF';
                    case 'TUMBLR' :
                        return forIcon ? ['fab', 'tumblr'] : '#34526f; color: #FFFFFF';
                    case 'TWITTER' :
                        return forIcon ? ['fab', 'twitter'] : '#1DA1F2; color: #FFFFFF';
                    case 'REDDIT' :
                        return forIcon ? ['fab', 'reddit-alien'] : '#FF5700; color: #FFFFFF';
                    case 'LINKEDIN' :
                        return forIcon ? ['fab', 'linkedin-in'] : '#0e76a8; color: #FFFFFF';
                    case 'SNAPCHAT' :
                        return forIcon ? ['fab', 'snapchat'] : '#FFFC00; color: #000000';
                    case 'TIK TOK' :
                        return forIcon ? ['fab', 'tiktok'] : '#000000; color: #FFFFFF';
                    case 'PINTEREST' :
                        return forIcon ? ['fab', 'pinterest'] : '#c8232c; color: #FFFFFF';
                    case 'LINE' :
                        return forIcon ? ['fab', 'line'] : '#00c300; color: #FFFFFF';
                    case 'TELEGRAM' :
                        return forIcon ? ['fab', 'telegram'] : '#0088CC; color: #FFFFFF';
                    case 'GOOGLE+' :
                        return forIcon ? ['fab', 'google-plus-g'] : '#d34836; color: #FFFFFF';
                    case 'MEETUP' :
                        return forIcon ? ['fab', 'meetup'] : '#ED1C40; color: #FFFFFF';
                    default:
                        return false;
                }
            },
            unmarkAndMarkAll(selected, allMarked) {
                if (allMarked === false || this.profiles.selected.length !== this.profiles.all.length) {
                    let temp = [];
                    if (this.$refs.dTable.allFilteredData) {
                        this.$refs.dTable.allFilteredData.forEach(function (item) {
                            temp.push(item.profileId.toString());
                        });
                    }
                    allMarked = true;
                    return (temp)
                }
                return ([])
            },
            updateProfiles() {
                if (this.profiles.linked.length > 0) { // Checking if there's any previous profiles
                    let countLinked = 0;
                    this.profiles.linked.forEach(Old => { // Looping through old to see if they're selected
                        if (this.profiles.selected.length > 0) { // Checking if there's anything selected
                            for (let j = 0; j < this.profiles.selected.length; j++) { // Looping through selected profiles to see if old one is in it
                                if (this.profiles.selected[j].toString() === Old.profileId.toString()) { // If old profile is in selected, take it out so only newly selected are left
                                    countLinked++;
                                    this.profiles.selected.splice(j, 1);
                                    if (countLinked === this.profiles.linked.length) { // Once done with old profiles, add any leftover selected profiles
                                        if (this.profiles.selected.length > 0) {
                                            let countSelected = 0;
                                            this.profiles.selected.forEach(Add => {
                                                risc.linkOrg(this.$route.params.id, Add).then(() => {
                                                    countSelected++;
                                                    if (countSelected === this.profiles.selected.length) {
                                                        this.profiles.modal = false;
                                                        this.getOrganization()
                                                    }
                                                });
                                            });
                                        } else {
                                            this.profiles.modal = false;
                                            this.getOrganization()
                                        }
                                    }
                                    break;
                                }
                                if (j === this.profiles.selected.length - 1) { // If old profile is not in selected, remove it
                                    risc.unlinkOrg(this.$route.params.id, Old.profileId).then(() => {
                                        countLinked++;
                                        if (countLinked === this.profiles.linked.length) { // Once done with old profiles, add any leftover selected profiles
                                            if (this.profiles.selected.length > 0) {
                                                let countSelected = 0;
                                                this.profiles.selected.forEach(Add => {
                                                    risc.linkOrg(this.$route.params.id, Add).then(() => {
                                                        countSelected++;
                                                        if (countSelected === this.profiles.selected.length) {
                                                            this.profiles.modal = false;
                                                            this.getOrganization()
                                                        }
                                                    });
                                                });
                                            } else {
                                                this.profiles.modal = false;
                                                this.getOrganization()
                                            }
                                        }
                                    });
                                }
                            }
                        } else { // Nothing was selected, remove old profile
                            risc.unlinkOrg(this.$route.params.id, Old.profileId).then(() => {
                                countLinked++;
                                if (countLinked === this.profiles.linked.length) { // Once done with old profiles, add any leftover selected profiles
                                    if (this.profiles.selected.length > 0) {
                                        let countSelected = 0;
                                        this.profiles.selected.forEach(Add => {
                                            risc.linkOrg(this.$route.params.id, Add).then(() => {
                                                countSelected++;
                                                if (countSelected === this.profiles.selected.length) {
                                                    this.profiles.modal = false;
                                                    this.getOrganization()
                                                }
                                            });
                                        });
                                    } else {
                                        this.profiles.modal = false;
                                        this.getOrganization()
                                    }
                                }
                            });
                        }
                    });
                } else { // If no previous profiles, everything checked is new
                    let countSelected = 0;
                    this.profiles.selected.forEach(Add => {
                        risc.linkOrg(this.$route.params.id, Add).then(() => {
                            countSelected++;
                            if (countSelected === this.profiles.selected.length) {
                                this.profiles.modal = false;
                                this.getOrganization()
                            }
                        });
                    });
                }
            },
            verifyThereAreNoNotes(notes) {
                try {
                    const notesLengthEqualToZero = notes.every(note => {
                        return note.text.length === 0;
                    })
                    return !!notesLengthEqualToZero;
                } catch (e) {
                    console.error(e)
                    return true
                }
            },
        }
    }
    /*Makes B-Form-Input All Caps*/
    Vue.directive('uppercase', {
        update(el) {
            el.value = el.value.toUpperCase()
        },
    })
</script>

<style scoped>
    .img-center {
        object-fit: contain;
        max-height: 360px;
        height: auto;
        width: 100%;
    }
    .img-center-ass {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 0;
    }
    .image-parent {
        background-color: #000000;
        flex-grow: 1;
        min-height: 0;
        display: flex;
        align-Items: center;
        justify-content: center;
    }
    .scroll {
        max-height: 1000px;
        overflow-y: auto;
    }
    .minScroll {
        max-height: 130px;
        overflow-y: auto;
    }
    .noAdded {
        text-align: center;
        margin-bottom: 0;
        color: grey
    }
    .rectangle {
        height: 85px;
        width: 85px;
        text-align: center;
        line-height: 90px;
        vertical-align: middle
    }
    .square {
        width: 25px;
        text-align: center;
        line-height: 25px;
        vertical-align: middle
    }
    .tabs-scroll {
        overflow-y: auto;
    }
    .tab-scroll {
        overflow-y: visible;
        min-height: 100%;
        background-color: #1a1a1a !important;
        border: none;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .descScroll {
        max-height: 500px;
        overflow-y: auto;
    }
    @media (min-width: 768px) {
        .row.equal {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .pac-container {
        z-index: 10000 !important;
    }
    .tab-content {
        flex-grow: 1;
        border-bottom: 2px solid #23282c;
        overflow-y: auto;
    }
</style>